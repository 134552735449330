<template>
	<IndexPopupEdit title="Деловодство"
		:props-list="propsList"
		:props-edit="propsEdit"
		:props-dialog="{width: 'unset', top: true}"
		:hide-insert="hideInsert"
		headers="utility_code,id,imot_address,station_address,created_time,deadline,current_status"
		:edit-control="EditProxy"
		:filter="filter"
	>
		<template #before-search>
			<quick-search-set :filter-group.sync="quickSearch">
				<v-sheet rounded elevation="2" class="ma-2 pa-1 d-flex align-center">
					<quick-search class="ma-1" style="max-width: 200px;" field="current_status" :op="FilterOps.EQUALS" />
					<v-divider vertical class="mx-2" />
					<quick-search class="ma-1" style="max-width: 200px;" field="id" :op="FilterOps.EQUALS" label="Вх./изх. номер" />
					<v-divider vertical class="mx-2" />
					<quick-search class="ma-1" style="max-width: 200px;" field="legal_case_num" :op="FilterOps.CONTAINS" label="Съд. дело" />
					<v-divider vertical class="mx-2" />
					<quick-search class="ma-1" style="max-width: 200px;" field="ref_num_other" :op="FilterOps.CONTAINS" label="Коресп. #" />
					<v-divider vertical class="mx-2" />
					<quick-search class="ma-1" style="max-width: 200px;" field="deadline" :op="FilterOps.LOWER_OR_EQUAL">
						<template #default="{rule}">
							<v-select :items="deadlineSelectOptions" v-model="rule.value" clearable outlined dense hide-details label="Срок за обработка" />
						</template>
					</quick-search>
					<v-divider vertical class="mx-2" />
					<quick-search class="ma-1" style="max-width: 300px;" field="imot_id" :op="FilterOps.EQUALS">
						<template #default="{rule}">
							<imot-select v-model="rule.value" outlined dense label="Имот" hide-details clearable />
						</template>
					</quick-search>
					<v-divider vertical class="mx-2" />
					<quick-search class="ma-1" style="max-width: 300px;" field="station_id" :op="FilterOps.EQUALS">
						<template #default="{rule}">
							<station-select-all v-model="rule.value" outlined dense label="Станция" hide-details clearable />
						</template>
					</quick-search>
					<v-divider vertical class="mx-2"/>
					<quick-search class="ma-1" style="max-width: 200px;" field="need_attension_izr" :op="FilterOps.EQUALS">
						<template #default="{rule}">
							<v-switch v-model="rule.value" label="за изр. сесия" hide-details/>
						</template>
					</quick-search>
				</v-sheet>
			</quick-search-set>
		</template>
		<template #list.item.id="{item}">
			<ref-num-tag :ref-num="item.id" />
		</template>
		<template #list.item.deadline="{item}">
			<ticket-deadline :date="item.__raw.deadline" :status="item.__raw.current_status" />
		</template>
		<template #list.item.current_status="{item}">
			<v-chip label small :color="TicketStatusMap.get(item.__raw.current_status)?.color" text-color="white" class="d-flex">
				<v-icon left small>{{ TicketStatusMap.get(item.__raw.current_status)?.icon }}</v-icon>
				{{ TicketStatusMap.get(item.__raw.current_status)?.label }}
			</v-chip>
		</template>
	</IndexPopupEdit>
</template>

<script>
import IndexPopupEdit from '@/ittijs/IndexPopupEdit.vue';
import Model from './Model';
import {RolesEnum} from "@/ittijs/Authorization";
import EditProxy from "@/views/tickets/EditProxy.vue";
import SelectedUtilityMixin from "@/ittijs/SelectedUtilityMixin";
import {FilterOps} from "@/ittijs/ITTIModel";
import {TicketStatusMap} from "@/ittijs/Nomenclatures";
import QuickSearch from "@/ittijs/QuickSearch.vue";
import QuickSearchSet from "@/ittijs/QuickSearchSet.vue";
import StationSelectAll from "@/views/stations/StationSelectAll.vue";
import ImotSelect from "@/views/imot/ImotSelect.vue";
import RefNumTag from "@/views/tickets/RefNumTag.vue";
import TicketDeadline from "@/views/tickets/TicketDeadline.vue";
import moment from "moment";

const imodel = new Model;

export default {
	mixins: [SelectedUtilityMixin],

	computed: {
		FilterOps() {
			return FilterOps
		},
		TicketStatusMap() {
			return TicketStatusMap
		},
		EditProxy() {
			return EditProxy
		},
		filter(){
			return {
				rules: [
					...(this.selectedUtility ? [{
						field: 'utility_code',
						op: FilterOps.EQUALS,
						value: this.selectedUtility,
					}] : []),
				],
				groups: {
					quickSearch: this.quickSearch,
				}
			}
		},
		deadlineSelectOptions(){
			const getOffset = days => moment().startOf('day').add(days, 'days').format('YYYY-MM-DD');
			return [
				{
					value: getOffset(-1),
					text: 'Изтекъл',
				},
				{
					value: getOffset(0),
					text: 'До 1 ден',
				},
				{
					value: getOffset(7),
					text: 'До седмица',
				},
			];
		},
	},

	components: {
		TicketDeadline,
		RefNumTag,
		ImotSelect,
		StationSelectAll,
		QuickSearchSet,
		QuickSearch,
		IndexPopupEdit,
	},

	provide: function () {
		return {
			imodel,
		};
	},

	inject: ['authorization'],

	data(){
		const noDelete = !this.authorization.hasRole(RolesEnum.TICKET_ADMIN);
		const readOnly = !this.authorization.hasAnyRole([RolesEnum.TICKET_ADMIN, RolesEnum.TICKET_NORMAL]);
		return {
			hideInsert: readOnly,
			propsList: {
				hideDelete: noDelete,
				hideSelect: noDelete,
				sortBy: 'id',
				sortDesc: true,
			},
			propsEdit: {
				editButtonOptions: {
					showDelete: !noDelete,
					showSave: !readOnly,
				},
				readOnly,
			},
			quickSearch: {},
		}
	},

}
</script>