<template>
	<div>
		<template v-if="!imodel.loaded"><v-progress-linear indeterminate /></template>
		<template v-else>
			<slot name="before-search" />
			<Search v-if="!hideSearch" @filter="filterFormData = $event" :defaults="defaultSearch" />
			<v-toolbar flat>
				<v-toolbar-title>{{ title || imodel.Title }}</v-toolbar-title>
				<v-spacer></v-spacer>
				<v-btn v-if="!hideInsert" @click="onAdd" dark color="primary"><v-icon left>mdi-plus</v-icon> Нов запис</v-btn>
			</v-toolbar>
			<List
				v-bind="propsList"
				ref="list"
				:headers="headers"
				:filter="filterCombined"
				@edit="onEdit"
			>
				<template v-for="slot of listSlots" v-slot:[slot]="scope"
					><slot :name="'list.'+slot" v-bind="scope"
				/></template>
			</List>
			<my-dialog v-bind="propsDialog" :value="isEdit" @close="onEditCancel">
				<component
					:is="editControl"
					hide-title
					v-bind="propsEdit"
					:id="id"
					v-if="isEdit"
					@saveclose="onSaveClose"
					@save="onSave"
					@close="cancelEdit"
					ref="EditForm"
				>
					<template v-for="slot of editSlots" v-slot:[slot]="scope"
						><slot :name="'edit.'+slot" v-bind="scope"
					/></template>
				</component>
			</my-dialog>
		</template>
	</div>
</template>

<script>
import List from "@/ittijs/List";
import Search from "@/ittijs/Search";
import IndexMixin from "@/ittijs/IndexMixin";
import IModelMixin from "@/ittijs/IModelMixin";
import EditColumn from "@/ittijs/EditColumn";
import MyDialog from "@/ittijs/components/MyDialog.vue";

export default {

	props: {
		'title': String,
		'headers': [String,Array],
		'hideSearch': Boolean,
		'hideInsert': Boolean,
		'propsList': Object,
		'propsEdit': Object,
		'propsDialog': Object,
		'editControl': {
			type: Object,
			default: () => EditColumn,
		},
		'filter': Object,
	},

	mixins: [
		IndexMixin,
		IModelMixin,
	],

	data: () => ({
		defaultSearch: {
			rules:[],
			groups:{
				g1:{rules:{}}
			},
		},
		filterFormData: {},
	}),

	components: {
		MyDialog,
		EditColumn,
		Search,
		List,
	},

	computed: {
		// slots for the list control, prefixed with "list."
		listSlots(){
			const prefix = 'list.';
			return Object.keys(this.$scopedSlots)
				.filter(slot => slot.startsWith(prefix))
				.map(slot => slot.substring(prefix.length));
		},
		// slots for the edit control, prefixed with "edit."
		editSlots(){
			const prefix = 'edit.';
			return Object.keys(this.$scopedSlots)
				.filter(slot => slot.startsWith(prefix))
				.map(slot => slot.substring(prefix.length));
		},
		filterCombined(){
			if (this.filter) {
				return {
					groups: {
						g1: this.filterFormData,
						g2: this.filter,
					}
				}
			}
			return this.filterFormData;
		},
	},

}
</script>